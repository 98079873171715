<template>
  <div class="w-screen">
    <store-header isAdmin :company="company" />
    <div class="container grid grid-cols-12 gap-8 ">
      <div class="col-start-4 col-span-9">
        <h1 class="font-bold text-2xl capitalize">
          {{ company.name }}
        </h1>
      </div>
      <div class="col-span-3">
        <div class="mb-4">
          <router-link
            :to="menu.link"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
            v-for="(menu, m) in activeItems"
            :key="m"
          >
            <p class="flex-grow">
              {{ menu.label }}
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
        </div>
      </div>
      <div class="col-span-9">
        <router-view></router-view>
      </div>
      <!-- <pre>
      {{ currentUserData }}
    </pre
      > -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
// import { API_URL } from "@/app-axios.js";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import StoreHeader from "./_components/StoreHeader.vue";
// import { Auth } from "aws-amplify";

export default {
  components: { StoreHeader },
  data() {
    return {
      menuItems: [
        {
          link: `/${this.$route.params.lang}/store/admin/editstore`,
          label: "Il mio Negozio",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/addproduct`,
          label: "Aggiungi Prodotti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/editproducts`,
          label: "Visualizza Prodotti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/orders-received`,
          label: "Ordini Ricevuti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/orders-sent`,
          label: "Ordini Effettuati",
          active: false
        }
      ]
    };
  },
  async mounted() {
    // console.log("COMPANY ID ON MOUNTED ", this.companyId);
    // if (!this.companyId) {
    //   await this.$store.dispatch("auth/getCurrentUser");
    //   console.log("Company Id non esiste. lo cerco", this.currentUserData);
    //   this.$store.commit(
    //     "products/SET_COMPANY_ID",
    //     this.$store.getters["auth/companyId"]
    //   );
    // }
    // console.log(
    //   "%cCarico le informazioni sullo store",
    //   "color:teal; font-size:1.3rem",
    //   this.companyId
    // );
    // await this.loadStore();
  },

  computed: {
    ...mapGetters("auth", ["currentUserData"]),
    ...mapGetters("products", ["companyId"]),
    ...mapState("createstore", ["company"]),
    activeItems() {
      return this.menuItems.filter(m => m.active);
    }
  },
  methods: {
    ...mapActions("createstore", ["loadStore"]),
    ...mapMutations("createstore", [
      "SET_COMPANY_COVER_PHOTO",
      "SET_COMPANY_PROFILE_PHOTO"
    ]),
    async uploadCoverPhoto(e) {
      console.log("Upload Photo Cover");
      let filePath = "img%2Fcover%2F"; // : "img%2Fprofile%2F";
      let file = e.target.files[0]; // : this.$refs.profilepic;
      try {
        let filename = file.name.split(".");
        let ext = filename[filename.length - 1];
        console.log("EXTENSION ", file, filename, ext);
        let options = {
          url: `${process.env.API_URL}/uploads/${filePath}${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": file.type
          },
          data: file
        };

        let res = await Axios(options);
        setTimeout(() => {
          //this.SET_PRODUCT_PHOTO(res.data.url);
          this.SET_COMPANY_COVER_PHOTO(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);

        let companyImg = { ...options };
        companyImg.url = `${process.env.API_URL}/companies/${this.company.company_id}/update-images`;
        companyImg.headers[
          "aws-access-token"
        ] = this.$store.state.auth.currentUserData.signInUserSession.accessToken.jwtToken;
        companyImg.headers["Content-Type"] = "application/json";
        companyImg.data = { company_header_promotion: res.data.url };

        console.log("Invio immagine al DB", companyImg);
        //: { company_logo: res.data.url };

        let upd = await Axios(companyImg);
        console.log("Immagine Modificata ", upd);
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    },
    async uploadProfilePic(e) {
      console.log("Upload Photo Cover");
      let filePath = "img%2Fprofile%2F"; // : "img%2Fprofile%2F";
      let file = e.target.files[0]; // : this.$refs.profilepic;
      try {
        let filename = file.name.split(".");
        let ext = filename[filename.length - 1];
        console.log("EXTENSION ", file, filename, ext);
        let options = {
          url: `${process.env.API_URL}/uploads/${filePath}${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": file.type
          },
          data: file
        };

        let res = await Axios(options);
        setTimeout(() => {
          //this.SET_PRODUCT_PHOTO(res.data.url);
          this.SET_COMPANY_PROFILE_PHOTO(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);

        let companyImg = {
          url: `${process.env.API_URL}/companies/${this.company.company_id}/update-images`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "aws-access-token": this.$store.state.auth.currentUserData
              .signInUserSession.accessToken.jwtToken
          },
          data: { company_logo: res.data.url }
        };

        console.log("Invio immagine al DB", companyImg);
        //:

        let upd = await Axios(companyImg);
        console.log("Immagine Modificata ", upd);
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    }
  }
};
</script>

<style lang="postcss">
.active-sub {
  @apply bg-gray-100 font-bold text-primary-500;
}
</style>
