<template>
  <div>
    <div class="cover min-h-64 relative bg-gray-500 z-0 text-xs">
      <img
        v-if="company.company_header_promotion"
        :src="company.company_header_promotion"
        class="w-full h-full  object-cover absolute top-0 left-0"
      />
      <div v-if="isAdmin">
        <input
          type="file"
          class="hidden"
          ref="coverpic"
          accept="image/*"
          @change="uploadCoverPhoto"
        />
        <button
          class="absolute right-0 mt-6 mr-4 bg-white rounded-md px-4 py-2 hover:bg-gray-100 text-gray-600"
          @click="$refs.coverpic.click()"
        >
          <i class="mdi mdi-image"></i>
          <span class=""> Modifica immagine Copertina</span>
        </button>
      </div>
    </div>
    <div class="-mt-32 z-10 relative container">
      <div
        class="w-32 h-32 md:w-64 md:h-64 border-2 border-white bg-gray-300 rounded-full flex justify-center items-center"
      >
        <img
          v-if="company.company_logo"
          :src="company.company_logo"
          class="w-32 h-32 md:w-64 md:h-64 object-cover rounded-full absolute top-0 left-0 border-4 border-white shadow-md"
        />
        <div v-if="isAdmin" class="flex justify-center items-center">
          <button @click="$refs.profilepic.click()" class="absolute z-30">
            <i class="mdi mdi-image text-2xl bg-white px-6 py-1 rounded-md"></i>
          </button>
          <input
            type="file"
            ref="profilepic"
            class="hidden"
            accept="image/*"
            @change="uploadProfilePic"
          />
        </div>
      </div>
      <div class="flex-grow">&nbsp;</div>
      <!-- <h1 class="font-bold text-2xl mt-24 ml-8 capitalize">
          {{ company.name }}
        </h1> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import { API_URL } from "@/app-axios.js";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

// import { Auth } from "aws-amplify";

export default {
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  async mounted() {
    if (!this.companyId) {
      await this.$store.dispatch("auth/getCurrentUser");
      console.log("Company Id non esiste. lo cerco", this.currentUserData);
      this.$store.commit(
        "products/SET_COMPANY_ID",
        this.$store.getters["auth/companyId"]
      );
    }

    //await this.loadStore();
  },

  computed: {
    ...mapGetters("auth", ["currentUserData"]),
    ...mapGetters("products", ["companyId"])
    //...mapState("createstore", ["company"])
  },
  methods: {
    ...mapActions("createstore", ["loadStore"]),
    ...mapMutations("createstore", [
      "SET_COMPANY_COVER_PHOTO",
      "SET_COMPANY_PROFILE_PHOTO"
    ]),
    async uploadCoverPhoto(e) {
      console.log("Upload Photo Cover");
      let filePath = "img%2Fcover%2F"; // : "img%2Fprofile%2F";
      let file = e.target.files[0]; // : this.$refs.profilepic;
      try {
        let filename = file.name.split(".");
        let ext = filename[filename.length - 1];
        console.log("EXTENSION ", file, filename, ext);
        let options = {
          url: `${process.env.API_URL}/uploads/${filePath}${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": file.type
          },
          data: file
        };

        let res = await Axios(options);
        setTimeout(() => {
          //this.SET_PRODUCT_PHOTO(res.data.url);
          this.SET_COMPANY_COVER_PHOTO(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);

        let companyImg = { ...options };
        companyImg.url = `${process.env.API_URL}/companies/${this.company.company_id}/update-images`;
        companyImg.headers[
          "aws-access-token"
        ] = this.$store.state.auth.currentUserData.signInUserSession.accessToken.jwtToken;
        companyImg.headers["Content-Type"] = "application/json";
        companyImg.data = { company_header_promotion: res.data.url };

        console.log("Invio immagine al DB", companyImg);
        //: { company_logo: res.data.url };

        let upd = await Axios(companyImg);
        console.log("Immagine Modificata ", upd);
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    },
    async uploadProfilePic(e) {
      console.log("Upload Photo Cover");
      let filePath = "img%2Fprofile%2F"; // : "img%2Fprofile%2F";
      let file = e.target.files[0]; // : this.$refs.profilepic;
      try {
        let filename = file.name.split(".");
        let ext = filename[filename.length - 1];
        console.log("EXTENSION ", file, filename, ext);
        let options = {
          url: `${process.env.API_URL}/uploads/${filePath}${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": file.type
          },
          data: file
        };

        let res = await Axios(options);
        setTimeout(() => {
          //this.SET_PRODUCT_PHOTO(res.data.url);
          this.SET_COMPANY_PROFILE_PHOTO(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);

        let companyImg = {
          url: `${process.env.API_URL}/companies/${this.company.company_id}/update-images`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "aws-access-token": this.$store.state.auth.currentUserData
              .signInUserSession.accessToken.jwtToken
          },
          data: { company_logo: res.data.url }
        };

        console.log("Invio immagine al DB", companyImg);
        //:

        let upd = await Axios(companyImg);
        console.log("Immagine Modificata ", upd);
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    }
  }
};
</script>

<style></style>
